import { FlowerIcon } from '@rfh/ui/shared/floriday-icons'
import {
  Card,
  CardActionArea,
  CardHeader,
  Container,
  Grid,
  Typography,
  styled,
} from '@mui/material'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ThemeConfig } from 'src/common/config'

const StyledDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
}))
const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(ThemeConfig.spacing.small),
  minHeight: 'calc(100vh - 64px - 8px)', // 64px: appbar, 8px: rainbow
  [theme.breakpoints.only('xs')]: {
    minHeight: 'calc(100vh - 64px)',
  },
}))

type HomePageProps = Record<string, never>
const HomePage: FC<HomePageProps> = (props: HomePageProps): ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()

  const onClickHandler = (): void => {
    history.push('/abbreviations')
  }

  return (
    <StyledDiv>
      <StyledContainer maxWidth='lg'>
        <Grid container direction='column' spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h2' component='h1'>
              {t('applicationName')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card>
              <CardActionArea onClick={onClickHandler}>
                <CardHeader
                  avatar={<FlowerIcon />}
                  title={t('abbreviations')}
                />
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </StyledContainer>
    </StyledDiv>
  )
}

export default HomePage
