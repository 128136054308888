/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/core'

const { PushNotifications } = Plugins
let savedToken: string

export const initializeNativeNotificationsAsync = async (): Promise<string> => {
  if (!savedToken) {
    const result = await PushNotifications.requestPermission()
    if (!result.granted) {
      throw new Error('user did not provide permission!')
    }
    const { value: token } = await registerForNativePushNotificationsAsync()

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotification) => {
        for (const callback of onPushNotificationCbs) {
          callback(notification)
        }
      }
    )
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        for (const callback of onPushNotificationTappedCbs) {
          callback(notification)
        }
      }
    )
    savedToken = token
  }
  return savedToken
}

export const registerForNativePushNotificationsAsync =
  (): Promise<PushNotificationToken> =>
    new Promise((resolve, reject) => {
      PushNotifications.addListener('registration', token => resolve(token))
      PushNotifications.addListener('registrationError', token => reject(token))
      PushNotifications.register()
    })
export type onPushNotificationTappedCallback = (
  notification: PushNotificationActionPerformed
) => void
const onPushNotificationTappedCbs: onPushNotificationTappedCallback[] = []
export const onPushNotificationTapped = (
  callback: onPushNotificationTappedCallback
) => onPushNotificationTappedCbs.push(callback)
//the reason why we add generic callbacks for this is so that one method can handle both browser notidications and capacitor notifications
export type onPushNotificationCallback = (
  notification: PushNotification
) => void
const onPushNotificationCbs: onPushNotificationCallback[] = []
export const onPushNotification = (callback: onPushNotificationCallback) => {
  onPushNotificationCbs.push(callback)
}
