/* eslint-disable @typescript-eslint/require-await */
import { FC, ReactElement, Suspense } from 'react'
import { useHistory } from 'react-router-dom'
import Routes from 'src/common/components/Routes'
import { ConfirmationProvider } from 'src/common/providers/ConfirmationProvider'
import 'src/i18n'
import { Layout } from './common/components/Layout/Layout'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { Config } from 'src/common/config'
import { PushNotificationProvider } from './modules/pushNotifications/PushNotificationProvider'
import { SnackBarProvider } from './common/components/SharedSnackBar'

const oktaAuth = new OktaAuth(Config.oidc)

const App: FC = (): ReactElement => {
  const history = useHistory()
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }
  return (
    <SnackBarProvider>
      <PushNotificationProvider>
        <ConfirmationProvider>
          <Suspense fallback={null}>
            <Security
              oktaAuth={oktaAuth}
              restoreOriginalUri={restoreOriginalUri}
            >
              <Layout>
                <Routes />
              </Layout>
            </Security>
          </Suspense>
        </ConfirmationProvider>
      </PushNotificationProvider>
    </SnackBarProvider>
  )
}
export default App
