import { IOfflineEvent, getLocalItem, removeLocalItem } from './OfflineManager'
import {
  removeAbbreviation,
  updateAbbreviation,
  addAbbreviation,
} from 'src/common/services'

export const processOfflineAbbreviationEvents = (accessToken: string): void => {
  const localStorageKey = 'offlineEvents'
  const events = getLocalItem<IOfflineEvent[]>(localStorageKey)

  events.forEach((event): void => {
    // arguments are saved with the spread operator so we can destructure them in a generic way
    const [arg1] = event.args
    // destructure like below when needing more args
    // const [arg1, arg2, ...etc] = event.args
    switch (event.name) {
      case 'removeAbbreviation':
        removeAbbreviation(arg1, accessToken).then((): void | Promise<void> =>
          removeLocalItem(localStorageKey, event.id)
        )
        break
      case 'updateAbbreviation':
        updateAbbreviation(arg1, accessToken).then((): void | Promise<void> =>
          removeLocalItem(localStorageKey, event.id)
        )
        break
      case 'addAbbreviation':
        addAbbreviation(arg1, accessToken).then((): void | Promise<void> =>
          removeLocalItem(localStorageKey, event.id)
        )
        break
    }
  })
}
