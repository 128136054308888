import { Grid, Dialog, Box, styled } from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import { Button, Container, RfhTheme } from '@rfh/ui'
import { FC, ReactElement } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AbbreviationDetailFields } from 'src/common/components/AbbreviationDetailFields'
import { FormMode } from 'src/common/enums'
import { IAbbreviation } from 'src/common/interfaces'
import { addAbbreviation } from 'src/common/services'
import { ThemeConfig } from 'src/common/config'

type NewAbbreviationModalProps = {
  close: (abbrData?: IAbbreviation) => void
  isOpen: boolean
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(ThemeConfig.spacing.small),
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const NewAbbreviationModal: FC<NewAbbreviationModalProps> = (
  props: NewAbbreviationModalProps
): ReactElement => {
  const { close, isOpen } = props
  const methods = useForm()
  const { t } = useTranslation()
  const { authState } = useOktaAuth()

  const onSubmit = async (item?: any): Promise<void> => {
    const addData = async (abbrItem: any) => {
      try {
        await addAbbreviation(abbrItem, authState.accessToken?.accessToken)
      } catch (error) {
        console.log(error)
        close()
      }
    }

    await addData(item)
    close()
  }

  return (
    <RfhTheme>
      <FormContext {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Dialog fullScreen open={isOpen} onClose={(): void => close()}>
            <AbbreviationDetailFields mode={FormMode.add} />
            <Box mt={3}>
              <StyledGrid
                container
                direction='row'
                justifyContent='center'
                xs={3}
                spacing={1}
              >
                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    fullWidth
                    onClick={methods.handleSubmit(onSubmit)}
                  >
                    {t('submit')}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='outlined'
                    fullWidth
                    onClick={() => methods.reset()}
                  >
                    {t('reset')}
                  </Button>
                </Grid>
              </StyledGrid>
            </Box>
          </Dialog>
        </form>
      </FormContext>
    </RfhTheme>
  )
}

export default NewAbbreviationModal
