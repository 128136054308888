/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable no-template-curly-in-string */
import { useOktaAuth } from '@okta/okta-react'
import { icons, RfhAppBar } from '@rfh/ui'
import { RfhPrimaryMenuProps } from '@rfh/ui/components/RfhPrimaryMenu/RfhPrimaryMenu'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import i18n from 'src/i18n'
import packageJson from '../../../../package.json'

export const AppBar: FC = () => {
  const [versionState] = useState(packageJson.version)
  const { oktaAuth } = useOktaAuth()
  const { t } = useTranslation()
  const history = useHistory()
  const { FlagGB, FlagNL, RfhLogoMini } = icons
  const getFlag = (): JSX.Element =>
    i18n.language?.substr(0, 2) === 'nl' ? (
      <FlagNL />
    ) : (
      <FlagGB /> //forcing hook on icon
    )

  const nextLanguageAsync = (): void => {
    i18n.changeLanguage(i18n.language?.substr(0, 2) === 'nl' ? 'en' : 'nl')
  }
  const login = async () => {
    oktaAuth.setOriginalUri(window.location.pathname)
    oktaAuth.signInWithRedirect('/')
  }
  const primaryMenuProps: RfhPrimaryMenuProps = {
    items: [
      {
        text: t('changeLanguage'),
        icon: getFlag(),
        isFooter: true,
        onClick: (event: React.MouseEvent<Element, MouseEvent>) =>
          nextLanguageAsync(),
      },
      {
        text: t('loginWithOkta'),
        icon: <RfhLogoMini />,
        isFooter: true,
        onClick: (event: React.MouseEvent<Element, MouseEvent>) => login(),
      },
    ],
  }

  return (
    <>
      <RfhAppBar
        primaryMenu={primaryMenuProps}
        mode={'menuItems'}
        title={'Boilerplate PWA - ' + versionState}
        onLogoClick={() => {
          history.push('/')
        }}
      />
      <button onClick={() => window?.location.reload()}>reload</button>
    </>
  )
}

export default AppBar
