import { Container, styled } from '@mui/material'
import { Button } from '@rfh/ui'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { EmptyState, IllustrationType } from 'src/common/components'

type NotFoundProps = Record<string, never>
const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const NotFound: FC<NotFoundProps> = (props: NotFoundProps): ReactElement => {
  const history = useHistory()
  const { t } = useTranslation()

  const gotoHome = (): void => {
    history.push('/')
  }

  return (
    <StyledContainer maxWidth='lg'>
      <EmptyState
        title={t('notFoundTitle')}
        subtitle={t('notFoundSubtitle')}
        illustration={IllustrationType.notFound}
      />
      <Button variant='contained' color='primary' onClick={gotoHome}>
        {t('gotoHome')}
      </Button>
    </StyledContainer>
  )
}

export default NotFound
