/* eslint-disable no-shadow */
import { styled } from '@mui/material'
import { FC } from 'react'
import notFound from 'src/illustrations/pluto-page-not-found.png'
import { ThemeConfig } from 'src/common/config'

export enum IllustrationType {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  notFound = 'notFound',
}

type IllustrationProps = {
  type: IllustrationType
}

const StyledDiv = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  maxWidth: '40vw',
  marginTop: theme.spacing(ThemeConfig.spacing.large),
  [theme.breakpoints.only('xs')]: {
    marginTop: theme.spacing(ThemeConfig.spacing.small),
    maxWidth: '60vw',
  },
  [theme.breakpoints.only('sm')]: {
    marginTop: theme.spacing(ThemeConfig.spacing.medium),
    maxWidth: '50vw',
  },
  '& img': {
    width: 'inherit',
    height: 'inherit',
    objectFit: 'contain',
  },
}))

const Illustration: FC<IllustrationProps> = (props: IllustrationProps) => {
  const { type } = props
  return (
    <StyledDiv>
      {type === IllustrationType.notFound && (
        <img src={notFound} alt={'not found'} />
      )}
    </StyledDiv>
  )
}

export default Illustration
