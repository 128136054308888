/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/unbound-method */
import { AddIcon, SearchIcon } from '@rfh/ui/shared/floriday-icons'
import {
  Fab,
  FormControl,
  Input,
  InputAdornment,
  Table,
  styled,
} from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import { FC, ReactElement, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Direction } from 'src/common/enums'
import { useConfirmation } from 'src/common/hooks/useConfirmation'
import { useOfflineEventProcessor } from 'src/common/hooks/useOfflineEventProcessor'
import { IAbbreviation } from 'src/common/interfaces'
import { getAbbreviations, removeAbbreviation } from 'src/common/services'
import {
  createExclusiveFilter,
  IFilterArgument,
} from 'src/common/utils/createFilter'
import { createSorter } from 'src/common/utils/createSorter'
import AbbreviationListItem from './components/AbbreviationListItem'
import NewAbbreviationModal from './components/NewAbbreviationModal'
import { TableRowMock } from './components/TableRowMock'
import { ThemeConfig } from 'src/common/config'

type AbbreviationListProps = Record<string, never>
const StyledDiv = styled('div')(({ theme }) => ({
  flexGrow: ThemeConfig.spacing.xsmall,
}))
const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: `calc(${theme.spacing(ThemeConfig.spacing.small)}px + 56px)`, //TODO: RP: Temp fix voor appcontainer
  right: theme.spacing(ThemeConfig.spacing.small),
}))
const StyledTh = styled('th')(({ theme }) => ({
  textAlign: 'left',
}))
const StyledForm = styled('form')(({ theme }) => ({
  margin: theme.spacing(ThemeConfig.spacing.small),
}))
const StyledTable = styled(Table)(({ theme }) => ({
  margin: theme.spacing(ThemeConfig.spacing.xsmall, ThemeConfig.spacing.small),
}))

const AbbreviationList: FC<AbbreviationListProps> = (
  props: AbbreviationListProps
): ReactElement => {
  // REMOVE IF NOT NEEDED: Invoke useOfflineEventProcessor to process offline events
  useOfflineEventProcessor()

  const history = useHistory()
  const location = useLocation()
  const confirm = useConfirmation()
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm()
  const { authState } = useOktaAuth()
  const [abbreviations, setAbbreviations] = useState<IAbbreviation[]>([])
  const [newModalOpen, setNewModalOpen] = useState(false)

  const [sortOrder] = useState<Direction>(Direction.asc)
  const [sorters, setSorters] = useState([
    {
      property: 'abbreviation',
      direction: sortOrder,
    },
  ])

  const [filters, setFilters] = useState<IFilterArgument[]>(
    ['abbreviation', 'description'].map(
      (filter: string): IFilterArgument => ({ property: filter, value: '' })
    )
  )

  useEffect(() => {
    // This is an example of guarding fetches when Okta is authenticated
    // if (authState.isAuthenticated) {
    //   fetchData()
    // }
    fetchData()
    console.log(authState)
  }, [authState])

  const fetchData = async (): Promise<void> => {
    try {
      const result = await getAbbreviations(authState.accessToken?.accessToken)
      if (typeof result === 'string') {
        return console.log(result)
      }
      setAbbreviations(result)
    } catch (error) {
      console.log(error)
    }
  }

  const removeItem = async (
    id: string,
    text: string,
    text2: string,
    text3: string,
    text4: string
  ): Promise<void> => {
    try {
      await confirm({
        variant: 'danger',
        catchOnCancel: true,
        title: t(text),
        description: t(text2),
        okbuttontext: t(text3),
        cancelbuttontext: t(text4),
      })
    } catch (error) {
      if (error) {
        console.log(error)
      }
      return
    }

    const removeData = async (abbreviationId: any): Promise<void> => {
      try {
        // Call to API to delete row from DB
        await removeAbbreviation(
          abbreviationId,
          authState.accessToken?.accessToken
        )
        // Cosmetic 'delete' of abbreviation from the view (list)
        setAbbreviations(abbreviations.filter(a => a.id !== abbreviationId))
      } catch (error) {
        console.log(error)
      }
    }

    removeData(id)
  }

  const handleFilter = (fltr: any): void => {
    const value = fltr.searchText
    if (value !== '') {
      return updateFilter(value)
    }

    updateFilter('')
  }

  const updateFilter = (value: string): void =>
    setFilters((state: IFilterArgument[]): IFilterArgument[] =>
      state.map((filter: IFilterArgument): IFilterArgument => {
        filter.value = value
        return filter
      })
    )

  const gotoAbbreviation = (abbreviationId: string): void => {
    history.push(`${location.pathname}/${abbreviationId}`)
  }

  const handleClose = (): void => {
    fetchData()
    setNewModalOpen(false)
  }

  return (
    <StyledDiv>
      <StyledFab
        color='primary'
        aria-label='add'
        onClick={(): void => setNewModalOpen(!newModalOpen)}
      >
        <AddIcon />
      </StyledFab>

      <NewAbbreviationModal isOpen={newModalOpen} close={handleClose} />
      <StyledForm onSubmit={handleSubmit(handleFilter)}>
        <FormControl fullWidth>
          {/* <InputLabel htmlFor='searchText'>{t('searchString')}</InputLabel> */}
          <Input
            id='searchText'
            name='searchText'
            placeholder={t('searchString')}
            inputRef={register}
            onChange={handleSubmit(handleFilter)}
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </StyledForm>

      <StyledTable>
        <thead>
          <tr>
            <StyledTh
              onClick={(): void =>
                setSorters([
                  { property: 'abbreviation', direction: Direction.asc },
                ])
              }
            >
              {t('abbreviation')}
            </StyledTh>
            <StyledTh
              onClick={(): void =>
                setSorters([
                  { property: 'description', direction: Direction.asc },
                ])
              }
            >
              {t('dutchDescription')}
            </StyledTh>
            <StyledTh
              onClick={(): void =>
                setSorters([
                  { property: 'englishDescription', direction: Direction.asc },
                ])
              }
            >
              {t('englishDescription')}
            </StyledTh>
            <StyledTh>{t('description2')}</StyledTh>
            <StyledTh
              onClick={(): void =>
                setSorters([{ property: 'date', direction: Direction.asc }])
              }
            >
              {t('date')}
            </StyledTh>
            <StyledTh>{t('createdBy')}</StyledTh>
            <StyledTh>{t('delete')}</StyledTh>
          </tr>
        </thead>
        <tbody>
          {!abbreviations.length &&
            [...Array(20)].map(
              (_: unknown, index: number): ReactElement => (
                <TableRowMock key={index} />
              )
            )}

          {abbreviations.length &&
            abbreviations
              ?.filter(createExclusiveFilter(...filters))
              .sort(createSorter(...sorters))
              .map((abbreviation: IAbbreviation, index: number) => (
                // TODO put in component
                <AbbreviationListItem
                  key={index}
                  abbreviation={abbreviation}
                  gotoAbbreviation={gotoAbbreviation}
                  removeItem={removeItem}
                  classType='cell'
                ></AbbreviationListItem>
              ))}
        </tbody>
      </StyledTable>
    </StyledDiv>
  )
}

export default AbbreviationList
