import { Grid, Hidden } from '@mui/material'
import { TextField } from '@rfh/ui'
import { FC, ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormMode } from 'src/common/enums'

interface IProps {
  mode: FormMode
}
type PropsType = IProps

export const AbbreviationDetailFields: FC<PropsType> = (
  props: PropsType
): ReactElement => {
  const { mode } = props
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register } = useFormContext()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            type='string'
            id='abbreviation'
            name='abbreviation'
            placeholder={t('abbreviation')}
            inputRef={register}
            label={t('abbreviation')}
            required={mode === FormMode.add}
            disabled={mode !== FormMode.add}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            color='primary'
            type='string'
            id='description'
            name='description'
            inputRef={register}
            placeholder={t('dutchDescription')}
            label={t('dutchDescription')}
            disabled={mode === FormMode.view}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            color='primary'
            type='string'
            id='englishDescription'
            name='englishDescription'
            inputRef={register}
            placeholder={t('englishDescription')}
            label={t('englishDescription')}
            disabled={mode === FormMode.view}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            color='primary'
            type='string'
            name='description2'
            inputRef={register}
            placeholder={t('description2')}
            label={t('description2')}
            disabled={mode === FormMode.view}
            fullWidth
          />
        </Grid>
      </Grid>
      <Hidden xsUp={mode === FormMode.add}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              color='primary'
              type='string'
              id='created'
              name='created'
              inputRef={register}
              label={t('created')}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              color='primary'
              type='string'
              id='createdBy'
              name='createdBy'
              label={t('createdBy')}
              inputRef={register}
              disabled
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              color='primary'
              type='string'
              id='updated'
              name='updated'
              inputRef={register}
              label={t('updated')}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              color='primary'
              type='string'
              id='updatedBy'
              name='updatedBy'
              label={t('updatedBy')}
              inputRef={register}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              color='primary'
              type='number'
              id='version'
              name='version'
              label={t('version')}
              inputRef={register}
              disabled
              fullWidth
            />
          </Grid>
        </Grid>
      </Hidden>
    </>
  )
}
