import { FC, useState, ReactElement, SetStateAction } from 'react'
import { styled, Select, MenuItem } from '@mui/material'
import i18n, { supportedLanguages } from '../../i18n'

interface IProps {
  open: boolean
}
type PropsType = IProps

const LanguageSelector: FC<PropsType> = (props: PropsType): ReactElement => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language)

  const handleChange = (event: {
    target: { value: SetStateAction<string> }
  }) => {
    let language: string = event.target.value as string
    language = language.length > 2 ? language.substr(0, 2) : language
    setCurrentLanguage(language)
    i18n.changeLanguage(language)
  }

  return (
    <Select open={props.open} value={currentLanguage} onChange={handleChange}>
      {supportedLanguages.map(
        (language: string): ReactElement => (
          <MenuItem key={language} value={language}>
            {language}
          </MenuItem>
        )
      )}
    </Select>
  )
}

export default styled(LanguageSelector)
