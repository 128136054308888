// Firebase App (the core Firebase SDK) is always required and must be listed first
// For an optimal experience using Cloud Messaging, also add the Firebase SDK for Analytics.
import firebase from '@firebase/app'
import '@firebase/analytics'
import '@firebase/messaging'
import { Config } from 'src/common/config/Config'
const firebaseConfig = Config.firebase

export const initializeBrowserNotificationsAsync = async (): Promise<any> => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
  } else {
    firebase.app() // if already initialized, use that one
  }

  const messaging = firebase.messaging()
  const serviceWorkerRegistration = await navigator.serviceWorker.ready

  // Get a token from FCM using the firebase config above which has the project settings
  messaging.onMessage(notification => {
    // Default key/value from the browser (testing) or FCM(SNS)
    for (const callbackNotification of onPushNotificationCbs) {
      callbackNotification(notification)
    }
  })

  try {
    const token = await messaging.getToken({ serviceWorkerRegistration })
    console.log('Push Notifications - FCM Token: ', token)
    return token
  } catch (error) {
    console.log('[serviceWorkerNotifications] messaging.getToken(): ', error)
    throw new Error('Unable to register for push notifcations')
  }
}

export type onPushNotificationCallback = (notification: any) => void
const onPushNotificationCbs: onPushNotificationCallback[] = []
export const onBrowserPushNotification = (
  callbackNotification: onPushNotificationCallback
): any => {
  onPushNotificationCbs.push(callbackNotification)
}
