import { FC, ReactElement } from 'react'
import { DeleteIcon } from '@rfh/ui/shared/floriday-icons'
import { TableRow, IconButton, styled } from '@mui/material'
import { IAbbreviation } from 'src/common/interfaces'

type AbbreviationListItemProps = {
  abbreviation: IAbbreviation
  classType: string
  removeItem: (
    id: string,
    text: string,
    text2: string,
    text3: string,
    text4: string
  ) => void
  gotoAbbreviation: (id: string) => void
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({}))
const StyledTD = styled('td')(({ theme }) => ({
  background: 'linear-gradient(90deg, #ff7200 25%, #ecebeb 85%)',
  border: 0,
  borderRadius: 9,
  textAlign: 'left',
  paddingLeft: 10,
  '&:hover': {
    background: 'linear-gradient(90deg, #0cc988 25%, #ecebeb 85%)',
  },
}))

const abbreviationListItem: FC<AbbreviationListItemProps> = (
  props: AbbreviationListItemProps
): ReactElement => {
  const abbreviation = props.abbreviation
  const classType = props.classType
  return (
    <StyledTableRow key={abbreviation.id}>
      <StyledTD onClick={(): void => props.gotoAbbreviation(abbreviation.id)}>
        {abbreviation.abbreviation}
      </StyledTD>
      <td className={classType}>{abbreviation.description}</td>
      <td className={classType}>{abbreviation.englishDescription}</td>
      <td className={classType}>{abbreviation.description2}</td>
      <td className={classType}>{abbreviation.created}</td>
      <td className={classType}>{abbreviation.createdBy}</td>
      <td>
        <IconButton
          aria-label='delete'
          color='primary'
          onClick={(): void =>
            props.removeItem(
              abbreviation.id,
              'deleteConfirm',
              'cannotUndoWarning',
              'ok',
              'cancel'
            )
          }
        >
          <DeleteIcon />
        </IconButton>
      </td>
    </StyledTableRow>
  )
}

export default abbreviationListItem
