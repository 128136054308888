/* eslint-disable no-shadow */
export enum Direction {
  asc,
  desc,
}

export enum FormMode {
  add,
  edit,
  view,
}
