import { FC, ReactElement } from 'react'
import { Container, Typography, styled } from '@mui/material'
import Illustration, { IllustrationType } from './Illustration'
import { ThemeConfig } from 'src/common/config'

type EmptyStateProps = {
  illustration?: IllustrationType
  title: string
  subtitle?: string
}

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(ThemeConfig.spacing.small),
  '& img': {
    marginBottom: theme.spacing(ThemeConfig.spacing.medium),
  },
}))

const EmptyState: FC<EmptyStateProps> = (
  props: EmptyStateProps
): ReactElement => {
  const { title, subtitle, illustration } = props

  return (
    <StyledContainer maxWidth='lg'>
      {illustration && <Illustration type={illustration} />}
      <Typography variant='h3' gutterBottom={true}>
        {title}
      </Typography>
      {subtitle && <Typography variant='body1'>{subtitle}</Typography>}
    </StyledContainer>
  )
}

export default EmptyState
