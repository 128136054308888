import { useEffect, useCallback } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { processOfflineAbbreviationEvents } from '../utils/managers/OfflineAbbreviationManager'
import { isOnline } from '../utils/managers/OfflineManager'
import { useSnackBar } from '../components/SharedSnackBar'

// This processes events which have occured while being offline
// When the app is online again the events are processed by OfflineAbbreviationManager
export const useOfflineEventProcessor = (): void => {
  const { authState } = useOktaAuth()
  const snackBarContext = useSnackBar()

  const updateOnlineStatus = useCallback((): void => {
    if (!isOnline()) {
      snackBarContext.showSnackBar('You seem to be offline!', 'error')
    } else {
      snackBarContext.showSnackBar('You are back online!', 'info')
      processOfflineAbbreviationEvents(authState.accessToken?.accessToken)
    }
  }, [authState.accessToken?.accessToken])

  // Make sure we process all offline events when starting the app
  useEffect(
    (): void =>
      processOfflineAbbreviationEvents(authState.accessToken?.accessToken),
    [authState.accessToken?.accessToken]
  )

  useEffect((): (() => void) => {
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    return (): void => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [updateOnlineStatus])
}
