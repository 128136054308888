/* eslint-disable id-blacklist */
/* eslint-disable max-len */
import * as AWSService from './AWSService'
import {
  Capacitor,
  PushNotification,
  PushNotificationActionPerformed,
} from '@capacitor/core'
import {
  initializeNativeNotificationsAsync,
  onPushNotification,
  onPushNotificationTapped,
} from './pushNotifications/nativeNotificationService'
import {
  initializeBrowserNotificationsAsync,
  onBrowserPushNotification,
} from './pushNotifications/browserNotificationService'
import SNS from 'aws-sdk/clients/sns'

export interface PushMessage {
  title: string
  body: string
  data: any
  original: Record<string, any>
}

export interface GenericPushMessage extends PushMessage {
  background: boolean
}

// DOTNET BACKEND: Register client and subscribe to topic inside lambda
export const registerDotnetBackend = async (token: string): Promise<any> =>
  AWSService.SubscribeToTopicDotnet(token)

// AWS SDK: Register client and subscribe to topic
export const registerAndSubscribeToSnsAsync = async (
  token: string,
  topic?: string
): Promise<SNS.SubscribeResponse> => {
  const resp = await AWSService.registerDeviceAsync(token)
  if (!resp.EndpointArn) {
    throw new Error('Something went wrong registering device (EndpointArn)')
  }
  return AWSService.subscribeToTopicAsync(resp.EndpointArn, topic)
}

export const initializePushNotificationsAsync =
  async (): Promise<SNS.SubscribeResponse> => {
    AWSService.init()
    if (Capacitor.isNative) {
      onPushNotification(handleForegroundPushNotification)
      onPushNotificationTapped(handleBackgroundPushNotification)
    } else {
      onBrowserPushNotification(handleForegroundPushNotification)
    }
    const token = await (Capacitor.isNative
      ? initializeNativeNotificationsAsync()
      : initializeBrowserNotificationsAsync())

    // AWS SDK
    // return await registerAndSubscribeToSnsAsync(token)

    // DOTNET API
    return registerDotnetBackend(token)
  }

// Dotnet API: Show native notification when the app is INACTIVE
const handleBackgroundPushNotification = (
  bgMessage: PushNotificationActionPerformed
) => {
  const { notification } = bgMessage
  console.log('handleBackgroundPushNotification: ', bgMessage)

  return processGenericPushNotification({
    title: notification.title ?? 'BG: Default Title',
    body: notification.body ?? 'BG: Default Body',
    data: bgMessage,
    original: bgMessage,
    background: true,
  })
}

// Dotnet API: Show notification when the app is ACTIVE
const handleForegroundPushNotification = (fgMessage: PushNotification) => {
  let title, body
  console.log('handleForegroundPushNotification:', fgMessage)

  if (Capacitor.isNative) {
    title = fgMessage.title
    body = fgMessage.body
  } else {
    title = fgMessage.notification.title
    body = fgMessage.notification.body
  }

  processGenericPushNotification({
    title: title ?? 'FG: Default Title',
    body: body ?? 'FG: Default Body',
    data: fgMessage,
    original: fgMessage,
    background: true,
  })
}

// This is used for direct access on AWS resources implementation (push notifications)
// // AWS SDK: Show notification when the app is active
// const handleForegroundPushNotification = (message: PushNotification) => {
//   console.log({ fgMessage: message })
//   let {
//     title,
//     body,
//     data: { data },
//   } = message

//   try {
//     data = JSON.parse(data)
//   } catch (e) {
//     console.log(e)
//   }
//   if (Capacitor.isNative) {
//     title = `${title}`
//     body = `${body}`
//   } else {
//     title = `${message?.data?.title}`
//     body = `${message?.data?.message}`
//   }

//   processGenericPushNotification({
//     title,
//     body,
//     data,
//     original: message,
//     background: false,
//   })
// }

// // AWS SDK Data structure: Show native notification when the app is inactive
// const handleBackgroundPushNotification = (
//   bgMessage: PushNotificationActionPerformed
// ) => {
//   console.log('MOBILE - handleBackgroundPushNotification')
//   console.log({ bgMessage })
//   const { notification } = bgMessage
//   const { message } = notification.data

//   let { data } = notification.data
//   try {
//     data = JSON.parse(data)
//   } catch (e) {
//     console.log(e)
//   }
//   return processGenericPushNotification({
//     title: notification.data.title,
//     body: message,
//     data,
//     original: bgMessage,
//     background: true,
//   })
// }

type onGenericPushMessageCallback = (message: GenericPushMessage) => void
const genericPushNotificationCallbacks: onGenericPushMessageCallback[] = []
const processGenericPushNotification = (message: GenericPushMessage) =>
  genericPushNotificationCallbacks.forEach(cbPush => cbPush(message))
export const onPushNotificationGeneric = (
  cbPush: onGenericPushMessageCallback
): any => {
  genericPushNotificationCallbacks.push(cbPush)
}
