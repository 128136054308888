import React from 'react'
import { useSnackBar } from '../../common/components/SharedSnackBar'

import {
  initializePushNotificationsAsync,
  onPushNotificationGeneric,
} from './../../common/services/pushNotificationService'

export const PushNotificationProvider: React.FC = ({ children }) => {
  const snackBarContext = useSnackBar()

  React.useEffect(() => {
    initializePushNotificationsAsync()
    onPushNotificationGeneric(message => {
      snackBarContext.showSnackBar(message.body, 'info')
    })
  }, [])

  return <>{children}</>
}
