/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-unresolved */
import AWS, { SNS } from 'aws-sdk'
import { Config } from 'src/common/config'
import { v4 as uuid } from 'uuid'
import * as request from 'superagent'

let _initialized = false

export type PushMessageOut = {
  title: string
  body: string
  dataPushMessage: any
}

// ------------------------------------------------------------------
// This is the 'new' AWS SDK architecture
// Below you will find the conventional dotnet api calls
// ------------------------------------------------------------------
export const init = (): void => {
  if (!_initialized) {
    AWS.config.region = 'eu-west-1'
    const credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: Config.aws.IdentityPoolId,
    })
    AWS.config.update({
      credentials,
    })
    _initialized = true
  }
}

export const registerDeviceAsync = (
  Token: string,
  PlatformApplicationArn?: string
): Promise<SNS.CreateEndpointResponse> => {
  init()

  return new Promise((resolve, reject) => {
    const sns = new SNS()
    sns.createPlatformEndpoint(
      {
        PlatformApplicationArn:
          PlatformApplicationArn || Config.aws.PlatformApplicationArn,
        Token,
      },
      (err: AWS.AWSError, response: SNS.CreateEndpointResponse) => {
        if (err) return reject(err)
        resolve(response)
        console.log(response)
      }
    )
  })
}

export const subscribeToTopicAsync = (
  endpoint: string,
  topic?: string
): Promise<SNS.SubscribeResponse> => {
  init()
  return new Promise((resolve, reject) => {
    const sns = new SNS()
    sns.subscribe(
      {
        TopicArn: topic || Config.aws.topicArn,
        Protocol: 'application',
        Endpoint: endpoint,
        ReturnSubscriptionArn: true,
      },
      (err, response) => {
        if (err) return reject(err)
        resolve(response)
      }
    )
  })
}

export const publishToTopicAsync = (
  body: string,
  title: string,
  topic?: string,
  dataPushMessage?: any
): Promise<SNS.PublishResponse> => {
  init()
  return new Promise((resolve, reject) => {
    const sns = new SNS()
    sns.publish(
      {
        TopicArn: topic || Config.aws.topicArn,

        Message: generateMessage({ body, title, dataPushMessage }),
        MessageStructure: 'json',
      },
      (err, resp) => {
        if (err) return reject(err)
        resolve(resp)
      }
    )
  })
}

const generateMessage = ({ body, title, dataPushMessage }: PushMessageOut) => {
  dataPushMessage = { ...dataPushMessage }
  const GCM = {
    id: uuid(),
    notification: { title, body },
    dataPushMessage: { message: body, dataPushMessage, title },
  }

  const messages = {
    GCM: JSON.stringify(GCM),
    default: body,
  }
  return JSON.stringify(messages)
}

// ------------------------------------------------------------------
// This is the conventional Dotnet API Gateway/Lambda architecture
// ------------------------------------------------------------------

export const SubscribeToTopicDotnet = async (
  paramToken: string
): Promise<any> => {
  try {
    const response = await request
      .post(Config.aws.dotnetApiSubscribe)
      .set('Content-Type', 'application/json')
      .send(JSON.stringify({ token: paramToken }))
    console.log('SubscribeToTopicDotnet: ', response)
  } catch (error) {
    console.log(error)
  }
}

export const PublishMsgToTopicDotnet = async (
  body: string,
  title: string
): Promise<any> => {
  try {
    const response = await request
      .post(Config.aws.dotnetApiPublish)
      .set('Content-Type', 'application/json')
      .send(
        JSON.stringify({
          title,
          body,
        })
      )
    console.log(response)
  } catch (error) {
    console.log(error)
  }
}
