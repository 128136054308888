import { Container, styled } from '@mui/material'
import { RfhTheme } from '@rfh/ui'
import { FC, ReactChildren } from 'react'
import { AppBar } from './AppBar'
import { BottomNavigation } from './BottomNavigation'
import { ThemeConfig } from 'src/common/config'

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(ThemeConfig.spacing.smallmedium),
  paddingBottom: theme.spacing(ThemeConfig.spacing.xlarge),
}))

export const Layout: FC = props => (
  <RfhTheme>
    <AppBar />
    <StyledContainer maxWidth='lg'>
      {props.children as ReactChildren}
    </StyledContainer>
    <BottomNavigation />
  </RfhTheme>
)
