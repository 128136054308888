import { Box, Grid, Typography, styled } from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import { Button, Container } from '@rfh/ui'
import { FC, ReactElement, useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { AbbreviationDetailFields } from 'src/common/components/AbbreviationDetailFields'
import { FormMode } from 'src/common/enums'
import { useConfirmation } from 'src/common/hooks/useConfirmation'
import { IAbbreviation } from 'src/common/interfaces'
import {
  getAbbreviation,
  removeAbbreviation,
  updateAbbreviation,
} from 'src/common/services'
import { ThemeConfig } from 'src/common/config'

interface RouteParams {
  id: string
}

type AbbreviationDetailProps = Record<string, never>
const StyledGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(ThemeConfig.spacing.small),
  marginLeft: 'auto',
  marginRight: 'auto',
}))
const AbbreviationDetail: FC<AbbreviationDetailProps> = (
  props: AbbreviationDetailProps
): ReactElement => {
  const [versionState, setVersionState] = useState(0)
  const history = useHistory()
  const params = useParams<RouteParams>()
  const confirm = useConfirmation()
  const { t } = useTranslation<string>()
  const { id } = params
  const { authState } = useOktaAuth()
  const formMethods = useForm<IAbbreviation>()

  const fetchData = async (abbreviationId: any): Promise<void> => {
    try {
      const result = await getAbbreviation(
        abbreviationId,
        authState.accessToken?.accessToken
      )
      setVersionState(result.version)
      formMethods.reset(result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (authState.isAuthenticated) {
      fetchData(id)
    }
  }, [authState])

  const removeItem = async (): Promise<void> => {
    const removeData = async (abbreviationId: string): Promise<void> => {
      try {
        await removeAbbreviation(
          abbreviationId,
          authState.accessToken?.accessToken
        )
      } catch (error) {
        console.log(error)
      }
    }

    if (id) {
      try {
        await confirm({
          variant: 'danger',
          catchOnCancel: true,
          title: t('deleteConfirm'),
          description: t('cannotUndoWarning'),
          okbuttontext: t('ok'),
          cancelbuttontext: t('cancel'),
        })
      } catch (error) {
        if (error) {
          console.log(error)
        }
        return
      }

      removeData(id)
      history.goBack()
    }
  }

  const updateData = async (abbr: IAbbreviation): Promise<void> => {
    try {
      await updateAbbreviation(abbr, authState.accessToken?.accessToken)
      fetchData(id)
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = (abbr: IAbbreviation): void => {
    abbr.id = id
    abbr.version = versionState
    updateData(abbr)
  }

  return (
    <Container>
      <Typography variant='h2' gutterBottom>
        {t('abbreviation')} Details
      </Typography>
      <FormContext {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <AbbreviationDetailFields mode={FormMode.edit} />
          <Box mt={3}>
            <StyledGrid
              container
              direction='row'
              justifyContent='center'
              xs={3}
              spacing={1}
            >
              <Grid item xs={12}>
                <Button
                  type='submit'
                  variant='contained'
                  fullWidth
                  onClick={(): any => formMethods.handleSubmit(onSubmit)}
                >
                  {t('submit')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant='outlined' fullWidth onClick={removeItem}>
                  {t('delete')}
                </Button>
              </Grid>
            </StyledGrid>
          </Box>
        </form>
      </FormContext>
    </Container>
  )
}

export default AbbreviationDetail
