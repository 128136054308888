import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'
import { LoginCallback, SecureRoute } from '@okta/okta-react'
import { FC, ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Config } from 'src/common/config'
import { AbbreviationDetail } from 'src/modules/abbreviations/views/abbreviation-detail'
import { AbbreviationList } from 'src/modules/abbreviations/views/abbreviation-list'
import { HomePage } from 'src/modules/general/views/homepage'
import { NotFound } from 'src/modules/general/views/notfound'
import { PushNotifications } from 'src/modules/pushNotifications/views/PushNotifications'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
  },
}))

const Routes: FC = (): ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Switch>
          <Route path='/' exact component={HomePage} />
          <Route path='/callback' component={LoginCallback} />
          <Route path='/pushnotifications' component={PushNotifications} />
          {Config.oktaRoute === 'on' ? (
            <SecureRoute
              path='/abbreviations'
              exact
              component={AbbreviationList}
            />
          ) : (
            <Route path='/abbreviations' exact component={AbbreviationList} />
          )}
          {Config.oktaRoute === 'on' ? (
            <SecureRoute
              path='/abbreviations/:id'
              exact
              component={AbbreviationDetail}
            />
          ) : (
            <Route
              path='/abbreviations/:id'
              exact
              component={AbbreviationDetail}
            />
          )}
          <Route component={NotFound} />
        </Switch>
      </main>
    </div>
  )
}

export default Routes
