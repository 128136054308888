import ReactDOM from 'react-dom'
import App from './App'
import { Capacitor } from '@capacitor/core'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { BrowserRouter as Router } from 'react-router-dom'

// Sentry is a tool for monitoring
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
Sentry.init({
  dsn: 'https://781257ac10ae401eaf35c18b82263495@o902103.ingest.sentry.io/5910898',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
})

// Service worker registration and serviceWorkerNotifications for FCM
if (Capacitor.getPlatform() === 'web') {
  serviceWorkerRegistration.register()
} else {
  serviceWorkerRegistration.unregister()
}

const rootNode = document.getElementById('root')
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  rootNode
)
